import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/fbert/OneDrive/Documents/wwwBertramFamilyCom/GatsbyJS/src/components/layout-mdx.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "400px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/1c4df69be94accbfb1ef825bf9db47e3/e17e5/birgit_med_hr.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "100%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAADyElEQVQ4y5WSXUyTdxjFz/t27GbJFmK225nFLE4k0DrroDCJjMnko6WWthQVtAwBBx0D2YYMAuIyGKgUSlE+BAZttEURKG1pebsWsbWFiThFHSQuGc5swLK4MFZi/ku7cbPYgSd5bp5znl/OxQP8Rxa1FKbGVNguHAHTlkE7urMxMXQCd8aq4dTl4PqlY7RTl49xTb7Px7qyNEtgahTB3pVFM+2ZcHRnh3r6S7qnzF90uvo+CnHpC+DSF9BjPTkYPSdbHzjSLIG1JZ0yKUWwqKRvjLZnLkwMlZKH0xpy16H8xaIWbh5p4sOlK6Q2BPQ1NDaKWFdq3ofhrEB5vTeX/D4/tkwIWf51zkws52SnR1okcOqLWcz5Axtr+MDxDfXHzTa4NXkXB7tqiNvNrDpd1lVmqJPc6i/RkMcMfvreSFnV0vWBoy1SLNy+xnpyZwyzVnXjrMtEJpwm7+w9l3fOYyazVtWZxSkDHk0aWBa1ZGMNfSKEwNb2IbM4bSEPmctPHzuHny5MjxBba9agd37F769l1wNS5iYRHB3yTcMNKfMzlsZHv9murCzZr67cY5p/Hm5I+dHRIX/VrBL5s+sCr9Zm4fSBTtSl3aUJQVCdtCv6vML6l/7LieWWPB1v8T5erOYTamkO0Fcp/h92nHcZqsMERRFaKMIHYGkHyuIMk5/FDJAm+Q1ySmB1ry6U+rOF3D605RN8+m7/s2EVm22oFdugzvagLs0e1JA5jq9EFsXne/pJefyQt7NoyluVZCSnBOb8+nQ76mT2IF/WdxMQWC+z48zBMVqZOY6zB6+92SR3egp2aLXlHxhIR+FNUho70Ks8PO5RH72xRSV3oiFjnK6XOQIDa1IZqjzOgIq9xuB6mf27WjGTmxva030idoB0H79NKhNMrVWJprz6dPtk5T7zKxV7jb6GVEBgZYKRdTLJjKoks7gsbtC6HeVBBVzNDwXcXtKqcJOv05kZAEFlcYPMyWTz/urkEVQlmuhnA1+3+X8LeGltRb+XIEBi/KFjIsHR+xJR7gOhICsnQSj2e2uhf24CNHwNmYhCBdgoQWoRn94vOYJ9fHGKUJLxbUrqIXuCQJqcKs2CuIRPb8cniEAZXkZa4Lcpjuzzz8cRWvDYfJrzdjTC2LzYcA7vUjiHpwvn8GI4O6MRxRHQhRFaFPP6/LMhvRWyg966jY0wdmRkSCi3JyR0Z28YO3LX1m0cv4fnVf/wLSz9ScB9J0YUvTteE7U7Xrsrco/wySqBwTrz3DzQNAWKol4AEAxg078TTFEUi6YDF/wbTAfB9glbXiEAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Birgit Bertram",
            "title": "Birgit Bertram",
            "src": "/static/1c4df69be94accbfb1ef825bf9db47e3/e17e5/birgit_med_hr.png",
            "srcSet": ["/static/1c4df69be94accbfb1ef825bf9db47e3/5a46d/birgit_med_hr.png 300w", "/static/1c4df69be94accbfb1ef825bf9db47e3/e17e5/birgit_med_hr.png 400w"],
            "sizes": "(max-width: 400px) 100vw, 400px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <br /><br />
    <center>
      <p>{`Birgit hasn't set up her website yet. Maybe one day she will.`}</p>
      <p>{`In the meantime, see what the `}<a parentName="p" {...{
          "href": "/"
        }}>{`other family members`}</a>{` have to say.`}</p>
    </center>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      